<template>

 <v-app>
   <div
       class="flex-row-fluid d-flex flex-column position-relative p-14 overflow-hidden"
   >

     <div class="d-flex flex-column-fluid flex-center mt-lg-10 mt-lg-0" v-if="options">
       <div class="d-flex flex-column align-items-center align-center text-center ">
         <h1 class="mb-5 font-weight-bolder mt-20">{{  options[key[0]].value }}</h1>
         <h3 class="mb-10">{{ options[key[1]].value }}</h3>
           <div class="row">
               <div class="col-md-6">
                   <a href="#"  @click.prevent="$router.push({name:'pre-registration'})">
                     <img v-if="options[key[2]].value" :src="options[key[2]].value" alt="Online apply" style="height: 100px" />
                     <img v-else src="media/education/icons/apply_now.jpg" alt="Online apply" style="height: 100px" />
                   </a>
               </div>
               <div class="col-md-6">
                   <a href="#" class="mt-20" @click.prevent="$router.push({name:'results'})">
                     <img v-if="options[key[3]].value" :src="options[key[3]].value" alt="Online apply" style="height: 100px" />
                     <img v-else src="media/education/icons/exam_result.jpg" alt="Online apply" style="height: 100px"/>
                   </a>
                </div>
            </div>
           <p class="mt-10">
             {{ options[key[4]].value }}
           </p>

           <!--         <v-btn @click.prevent="$router.push({name:'pre-registration'})" depressed-->
<!--                class="rounded-pill p-5 btn btn-primary text-white col-12 ">-->
<!--           Submit online application-->
<!--         </v-btn>-->

         <!--                <v-btn @click.prevent="$router.push({name:'enrollment'})" depressed rounded-->
         <!--                       class="rounded-pill p-5 mt-5 btn btn-primary text-white col-12">-->
         <!--                    Student Enrollment-->
         <!--                </v-btn>-->

<!--             <v-btn depressed&ndash;&gt;-->
<!--                class="rounded-pill p-5 mt-5 btn btn-primary text-white col-12 ">-->
<!--           Entrance exam result-->
<!--         </v-btn>-->
       </div>
     </div>
     <div class="d-flex flex-column-fluid flex-center mt-lg-10 mt-lg-0" v-else>
       <div class="d-flex flex-column align-items-center align-center text-center ">
         <h1 class="mb-5 font-weight-bolder mt-20">Online Admission System</h1>
         <h3 class="mb-10">Please select your interest to continue.</h3>
         <div class="row">
           <div class="col-md-6">
             <a href="#"  @click.prevent="$router.push({name:'pre-registration'})">
               <img src="media/education/icons/apply_now.jpg" alt="Online apply" style="height: 100px" />
             </a>
           </div>
           <div class="col-md-6">
             <a href="#" class="mt-20" @click.prevent="$router.push({name:'results'})">
               <img src="media/education/icons/exam_result.jpg" alt="Online apply" style="height: 100px"/>
             </a>
           </div>
         </div>
         <p class="mt-10">
           If you are looking to apply, click 'Apply online'. If you are here to check your result, click 'Entrance results' to continue.
         </p>

       </div>
     </div>
   </div>
 </v-app>
</template>

<script>

import OptionService from "@/core/services/option/OptionService";

const option = new OptionService();
export default {
  name: "index",
  data() {
    return {
      options: null,
      key: [
        'ONLINE_ADMISSION_TITLE',
        'ONLINE_ADMISSION_SUBTITLE',
        'ONLINE_ADMISSION_APPLY_IMAGE',
        'ONLINE_ADMISSION_ENTRANCE_IMAGE',
        'ONLINE_ADMISSION_NOTES',
      ],
    }
  },
  computed: {
    token() {
      let token = localStorage.getItem('tk')
      return token ? token : false
    },
  },
  mounted() {
    this.getOptions();
  },
  methods: {
    getOptions(){
      option
          .getByKeys(this.key)
          .then(response => {
            if(response.data.data) {
              this.options = response.data.data;
            } else {
              this.options = null
            }
          }).catch(() => {})
    },
  }
}
</script>
